import styles from './Banner.module.scss';
import cn from 'classnames';
import { Button } from '../Button';
import { Tag } from '../Tag';
import { Backdrop } from '../Backdrop';
import { BACKEND_URL } from '@/constants';
import { ItemData } from '../Card/types';
import { parseText } from '@/utils/parseText';
import { useRouter } from 'next/router';
import { useAdaptiveWidth } from '@/hooks/useAdaptiveWidth';

export function Banner({
  name,
  info,
  tag,
  button,
  text,
  link,
  picture,
  pictureMob,
  className,
  gallery,
  video,
  videoMob,
  buttonLink,
}: ItemData & {
  gallery?: boolean;
}) {
  const cardClassName = cn(
    styles.banner,
    {
      [styles.gallery]: gallery,
      [styles.clickable]: link,
    },
    className,
  );

  const { isMobile } = useAdaptiveWidth();
  const router = useRouter();

  const imageUrl =
    isMobile && pictureMob
      ? `${BACKEND_URL}${pictureMob}`
      : picture
        ? `${BACKEND_URL}${picture}`
        : '/png/empty.png';

  const handleCardClick = () => {
    if (link && link.includes('http')) window.open(link, '_blank');
    if (link && !link.includes('http')) router.push(link);
    return;
  };

  const getBannerBackgroundTag = () => {
    if (isMobile && videoMob) {
      return (
        <video
          className={styles.img}
          autoPlay
          loop
          muted
          playsInline
          style={{ borderRadius: 16 }}
        >
          <source src={`${BACKEND_URL}${videoMob}`} type="video/mp4" />
        </video>
      );
    }

    if (video) {
      return (
        <video className={styles.img} autoPlay loop muted playsInline>
          <source src={`${BACKEND_URL}${video}`} type="video/mp4" />
        </video>
      );
    }

    return <img src={imageUrl} alt="object" className={styles.img} />;
  };

  return (
    <div className={cardClassName} onClick={handleCardClick}>
      {getBannerBackgroundTag()}
      <div></div>
      <div className={styles.info}>
        <div>
          {tag && <Tag text={tag} className={styles.tagBottom} />}
          {gallery ? (
            <div className={styles.titleBig}>{parseText(name)}</div>
          ) : (
            <div className={styles.title}>{parseText(name)}</div>
          )}
          {text && <div className={styles.description}>{parseText(text)}</div>}
          {button && (
            <Button
              text={button}
              onClick={() => window.open(buttonLink, '_blank')}
              className={styles.button}
            />
          )}
        </div>
        {info && <div className={styles.price}>{info}</div>}
      </div>
      {(name || text) && <Backdrop height={176} className={styles.backdrop} />}
    </div>
  );
}
